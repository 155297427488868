@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;900&display=swap');

body {
    font-family: 'Archivo', sans-serif;
    background-color: #242222;
    color: #ffffff;
}

/* Adjust font sizes for responsiveness */
@media (max-width: 576px) {
  .hero-title {
      font-size: 24px;
  }
  .hero-text {
      font-size: 18px;
  }
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .hero-content {
      padding: 30px 5%;
  }
  .form-control, .btn {
    width: 100% !important;
  }
}


.header-title {
  font-family: 'Archivo Black', sans-serif;
}

.active-color{
  color: #00bcd4; /* Adjust to the desired color for strong elements */
}

strong {
    color: #00bcd4; /* Adjust to the desired color for strong elements */
}

.strong{
  font-weight: bolder;
}

.logo {
  width: 14vw; /* Adjust as needed */
  min-width: 80px; 
  max-width: 150px; 
  padding: 20px 0px;
  position: absolute;
}

.header-links{
  display: flex;
  justify-content: center; /* Updated to center the links */
  gap: 10%;
  padding: 20px 10%;
}

.header-links a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Use the same color as the parent */
  transition: color 0.3s ease; /* Optional: smooth color transition on hover */
  cursor: pointer;
  font-size: larger;
}

.header-links a:hover {
  color: #00bcd4; /* Change color on hover to this cyan shade. Adjust as needed. */
}

/* Remove justify-content: center; from .hero-content to align text to the start */
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align to the start */
  gap: 20px;
  padding: 100px 10%;
  min-height: 100vh;
}

.hero-title {
    font-size: 10vw;
    font-weight: bold;
    line-height: normal;
}

.hero-subtext {
    font-size: 2.3rem;
}

.form-controls {
    display: flex;
    gap: 10px;
}

.form-control, .btn {
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 5px;
    outline: none;
    background-color: #f1f1f1; /* Light gray color for input and select */
    font-size: 18px;
}

select.form-control {
    appearance: none; /* Removes default style */
    padding-left: 10px; /* Adjust as needed */
    cursor: pointer;
}

.btn {
    background-color: #0194c5;
    color: #ffffff;
    font-weight: bold; /* Making the button text bold */
    transition: background-color 0.3s ease; /* Smooth color transition on hover */
}

.btn:hover {
    background-color: #00759f; /* Darker shade for hover */
}


.btn-scroll-down {
  background: none;
  border: none;
  cursor: pointer;
  fill: #00bcd4; 
}

.btn-scroll-down:hover .bi-arrow-down-circle-fill {
  fill: #02a1b6; /* The strong color used in your strong elements */
}

.bi-arrow-down-circle-fill {
  transition: fill 0.3s ease;
  fill: #00bcd4; 
}
.testimonial {
  position: relative;
  max-width: 250px;
  margin: auto;
  display: block !important;
}
.testimonial-card {
  position: relative;
  max-width: 300px;
  margin: auto;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}



.logo-google {
  background: url('https://rotulosmatesanz.com/wp-content/uploads/2017/09/2000px-Google_G_Logo.svg_.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px; /* Adjust as needed */
  height: 24px; /* Adjust as needed */
}

.testimonial-rating {
  color: #ffc107;
  margin-bottom: 10px;
}

.testimonial-text {
  min-height: 120px;
  position: relative;
}

.testimonial-footer {
  display: flex;
  align-items: center;
}

.testimonial-author-initials {
  width: 45px;
  height: 45px;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 5px;
  font-size: 1.2em;
}

.testimonial-author-info {
  text-align: left;
}

.testimonial-author {
  font-weight: bold;
  margin-bottom: 4px;
}

.testimonial-date {
  color: #888;
  font-size: 0.8rem;
}

.testimonial-arrow {
  position: relative;
  bottom: 10px; /* Adjust this value to match the height of the arrow */
  left: 10%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 20px solid transparent; /* Adjust size to match your design */
  border-right: 20px solid transparent; /* Adjust size to match your design */
  border-top: 20px solid #FFFFFF; /* Match this with the background color of the testimonial-card */
}


.footer .social-media-icons a{
  color: #f1f1f1;
  margin: 10px;
}

.footer a, .copyright{
  color: #666666;
  font-size: 13px;
}
